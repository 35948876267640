
export type AppPayload = {
    loading?: boolean;
    error?: AppError | null;
    tableHasUpdates?: number;
};

export type AppError = {
  title: string;
  errors: string[];
  persistError : boolean,
  warning?: boolean
}

export enum AppActionTypes {
    SET_LOADING = 'application/setLoading',
    SET_TABLE_HAS_UPDATES = 'application/table_has_updates',
    SET_ERROR = "application/setError",
}

export type AppState = {
  loading: boolean,
  error: AppError | null,
  tableHasUpdates: number;
}
