import { SelectOption } from "./InputTypes";

export const nameof = <T>(name: keyof T) => name;

export const enumToSelectOptions = <T extends { [name: string]: any }>(enumVal: T): SelectOption[] => {
  return Object.entries(enumVal).map((val) => {
    return {
      value: val[0],
      label: val[1],
    }
  });
}

export const enumToSelectOpt = <T extends { [name: string]: any }>(value: any, enumVal: T): SelectOption => {
  const found = Object.entries(enumVal).find((val) => val[1] === value);
  return {
    value: found?.[0] || null,
    label: found?.[1],
  };
}