import { PageSize } from "components";

export const getDefaultSizePerPageList = (data: any[], size?: number): PageSize[] => {
  return [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
    {
      text: "250",
      value: 250,
    },
    {
      text: "500",
      value: 500,
    },
    {
      text: "1000",
      value: 1000,
    },
    {
      text: "All",
      value: size ? size : data.length,
    },
  ]
};