import { Reducer } from 'redux';
import { BTAction } from 'redux/constants';
import { AppActionTypes, AppPayload, AppState } from './constants';

const initialState: AppState = {
    loading: false,
    error: null,
    tableHasUpdates: 0,
};

const reducer: Reducer<AppState, BTAction<AppPayload>> = (state = initialState, action) => {
    switch (action.type) {
        case AppActionTypes.SET_LOADING:
            return { ...state, ...action.payload };
        case AppActionTypes.SET_ERROR:
            return { ...state, ...action.payload };
        case AppActionTypes.SET_TABLE_HAS_UPDATES:
            return { ...state, tableHasUpdates: (state.tableHasUpdates += 1) };

        default:
            return state;
    }
};

export default reducer;
