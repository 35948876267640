export enum AuthActionTypes {
    SET_USER_AUTHENTICATION = '@@auth/SET_USER_AUTHENTICATION',

    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER = '@@auth/LOGIN_USER',
    OAUTH_LOGIN_USER = '@@auth/OAUTH_LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE = '@@auth/FORGOT_PASSWORD_CHANGE',

    RESET = '@@auth/RESET',

    UPDATE_IS_AUTHED = '@@auth/UPDATE_IS_AUTHED',
    UPDATE_IS_ACTIVE = '@@auth/UPDATE_IS_ACTIVE',
}
