import { Reducer } from "redux";
import { BTAction } from "redux/constants";
import {
    center, defaultFilterValues,
    defaultMapCoordinatesValues,
    defaultMapOptionsValues,
    defaultVehicleDetailsValues, FilterValuesInterface,
    MapActionTypes,
    MapState,
    MeasureUnitType,
    UncompletedTaskDetails
} from "./constants";
import { arraysEqual, getIndexNumberArray } from "../../pages/routing/fleet-view/complements/FleetUtils";

const initialState: MapState = {
    loading: true,
    error: null,
    mapIsMoving: false,
    trackingId: '',
    fleetMapOptions: defaultMapOptionsValues,
    zoomLevel: 3.5,
    vehicleList: [],
    vehicleListStatistics: {},
    vehicleId: '',
    vehicleDetails: defaultVehicleDetailsValues,
    tasksDetails: [],
    uncompletedTasksDetails: [],
    taskIdList: '',
    taskOutcomeList: [],
    updateMarkersLabel: false,
    fleetMarkerVehicleId: '',
    taskMarkerVehicleId: '',
    mapCoordinateValues: defaultMapCoordinatesValues,
    isDefaultCenteredMap: false,
    showOutcomeMarkersOnDetails: false,
    toggleMeasureUnit: MeasureUnitType.IMPERIAL,
    filterValues: defaultFilterValues,
};

const FleetView: Reducer<MapState, BTAction<MapState>> = (state = initialState, action) => {
    switch (action.type) {
        case MapActionTypes.SET_LOADING:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_MAP_IS_MOVING:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_MAP_FLEET_OPTIONS:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_ZOOM_LEVEL:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_VEHICLE_LIST:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_VEHICLE_LIST_STATISTICS:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_VEHICLE_ID:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_TASKS_ID_LIST:
            return {
                ...state,
                taskIdList: state.taskIdList !== action.payload.taskIdList ? action.payload.taskIdList : state.taskIdList,
            };
        case MapActionTypes.SET_TASKS_OUTCOME_LIST:
            return {
                ...state,
                taskOutcomeList: state.taskOutcomeList !== action.payload.taskOutcomeList ? action.payload.taskOutcomeList : state.taskOutcomeList,
            };
        case MapActionTypes.SET_MARKER_TRACKING_ID:
            return {
                ...state,
                trackingId: state.trackingId !== action.payload.trackingId ? action.payload.trackingId : '',
            };
        case MapActionTypes.SET_VEHICLE_DETAILS:
            let updatedTasks = [];

            // when a tasks are updated we need to take those values, then this data is added to outcome list and then updated the list
            if (action.payload.vehicleDetails?.tasks && state.vehicleDetails?.tasks && state.vehicleDetails?.tasks.length > 0){
                const previousTasks = state.vehicleDetails?.tasks;
                updatedTasks = action.payload.vehicleDetails?.tasks.filter((task: any) => previousTasks.find((previousTask) => previousTask.trackingId === task.trackingId && previousTask.outcome !== task.outcome));
            }

            return {
                ...state,
                vehicleDetails: {
                    ...state.vehicleDetails,
                    ...action.payload.vehicleDetails
                },
                taskOutcomeList: updatedTasks,
            };
        case MapActionTypes.SET_TASKS_DETAILS:
            return {
                ...state,
                ...action.payload,
                updateMarkersLabel: !arraysEqual(getIndexNumberArray(state?.tasksDetails ?? []), getIndexNumberArray(action.payload?.tasksDetails ?? [])),
            };
        case MapActionTypes.SET_UNCOMPLETED_TASKS_DETAILS:
            //we need to guarantee here are only fully uncompleted tasks and anyone has succeeded as outcome value
            const validTasks = state.vehicleDetails?.tasks?.filter((generalTask) => generalTask.type === 'DELIVERY' && generalTask.outcome !== null) ?? [];

            const uncompletedTasks: UncompletedTaskDetails[] = action.payload.uncompletedTasksDetails && action.payload.uncompletedTasksDetails.filter(
                uncompletedTask => {
                    const trackingId = uncompletedTask.trackingId || '';

                    const index = validTasks.findIndex((generalTask) => {
                        const trackingIdActual = generalTask && 'trackingId' in generalTask && generalTask.trackingId || '';
                        return  trackingIdActual === trackingId;
                    });

                    return index ? index < 0 : -1;
                  }
            ) || [];

            return {
              ...state,
                uncompletedTasksDetails: uncompletedTasks,
            };
        case MapActionTypes.SET_UPDATE_MARKERS_LABEL:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_FLEET_MARKER_VEHICLE_ID:
            return {
                ...state,
                fleetMarkerVehicleId: state.fleetMarkerVehicleId !== action.payload.fleetMarkerVehicleId ? action.payload.fleetMarkerVehicleId : '',
            };
        case MapActionTypes.SET_TASK_MARKER_VEHICLE_ID:
            return {
                ...state,
                taskMarkerVehicleId: state.taskMarkerVehicleId !== action.payload.taskMarkerVehicleId ? action.payload.taskMarkerVehicleId : '',
            };
        case MapActionTypes.SET_MAP_COORDINATE_VALUES:
            const metroCoordinates = action.payload.mapCoordinateValues?.center || center;
            const metroZoomValue = action.payload.mapCoordinateValues?.zoom || 10;

            return {
                ...state,
                mapCoordinateValues: {
                    center: {
                        ...state.mapCoordinateValues?.center,
                        ...metroCoordinates,
                    },
                    zoom: metroZoomValue,
                    northEast: action.payload.mapCoordinateValues?.northEast ?? undefined,
                    southWest: action.payload.mapCoordinateValues?.southWest ?? undefined,
                },
            };
        case MapActionTypes.SET_IS_DEFAULT_CENTERED_MAP:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_SHOW_OUTCOME_MARKERS_ON_DETAILS:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_TOGGLE_MEASURE_UNIT:
            return { ...state, ...action.payload };
        case MapActionTypes.SET_FILTER_VALUES:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...action.payload.filterValues
                },
            };
        default:
            return state;
    }
};

export default FleetView;
