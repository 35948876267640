import jwtDecode from 'jwt-decode';

interface IParsedJwt {
    userId: string;
    firstName: string;
    lastName: string;
}

export const parseJwt = (token: string): IParsedJwt => {
    return {
        userId: '0',
        firstName: '',
        lastName: '',
    };
};
