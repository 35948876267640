import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import useFileUploader from './useFileUploader';
import { useEffect } from 'react';
import showToast from 'utils/notification';

export type FileType = File & {
    preview?: string;
    formattedSize?: string;
};

type FileUploaderProps = {
    onFileUpload?: (files: FileType[]) => void;
    showPreview?: boolean;
    multiple?: boolean;
    compact?: boolean;
    isFromParcelStatus?: boolean;
    accept?: string | string[];
    onFileRemove?: () => void;
};

const FileUploader = (props: FileUploaderProps) => {
    const { selectedFiles, handleAcceptedFiles, removeFile } = useFileUploader(props.showPreview, props.multiple);

    const { getInputProps, open, acceptedFiles } = useDropzone({
        accept: props.accept,
    });

    useEffect(() => {
        handleAcceptedFiles(acceptedFiles, props.onFileUpload);
    }, [acceptedFiles]);

    if (props.isFromParcelStatus) {
        const selectedFileCount = selectedFiles.length;
        if (selectedFileCount >= 3) {
            while (selectedFiles.length > 3) {
                selectedFiles.pop();
            }
        }
        return (
            <div className="container d-flex">
                <div className="d-flex flex-column justify-content-center">
                    <Button variant="primary" onClick={open} disabled={selectedFiles.length >= 3}>
                        Select file
                    </Button>
                </div>
                <input {...getInputProps()} />
                {props.showPreview && selectedFileCount == 0 && (
                    <div className="d-flex ms-2 align-items-center">No item selected.</div>
                )}
                {props.showPreview && selectedFileCount > 0 && (
                    <div className="p-2 ms-2">
                        {selectedFiles && selectedFiles.map((file, index) => (
                            <Row className="align-items-center" key={index}>
                                <Col className="col-auto">
                                    <img
                                        data-dz-thumbnail=""
                                        className="avatar-sm rounded bg-light"
                                        alt={file.name}
                                        src={file.preview}
                                    />
                                </Col>
                                <Col className="ps-0">
                                    <Link to="#" className="text-muted fw-bold">
                                        {file.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{file.formattedSize}</strong>
                                    </p>
                                </Col>
                                <Col className="text-end">
                                    <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                                        <i
                                            className="dripicons-cross"
                                            onClick={() => {
                                                props.onFileRemove && props.onFileRemove();
                                                removeFile(file);
                                            }}
                                        ></i>
                                    </Link>
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </div>
        );

    }

    if (props.compact) {
        const selectedFile = selectedFiles.length > 0 ? selectedFiles[0] : null;
        return (
            <div className="container d-flex">
                <div className="d-flex flex-column justify-content-center">
                    <Button variant="primary" onClick={open}>
                        Select file
                    </Button>
                </div>
                <input {...getInputProps()} />
                {props.showPreview && !selectedFile && (
                    <div className="d-flex ms-2 align-items-center">No item selected.</div>
                )}
                {props.showPreview && selectedFile && (
                    <div className="p-2 ms-2">
                        <Row className="align-items-center">
                            {selectedFile.preview && (
                                <Col className="col-auto">
                                    <img
                                        data-dz-thumbnail=""
                                        className="avatar-sm rounded bg-light"
                                        alt={selectedFiles[0].name}
                                        src={selectedFile.preview}
                                    />
                                </Col>
                            )}
                            {!selectedFile.preview && !props.compact && (
                                <Col className="col-auto">
                                    <div className="avatar-sm">
                                        <span className="avatar-title bg-primary rounded">
                                            {selectedFile.type.split('/')[0]}
                                        </span>
                                    </div>
                                </Col>
                            )}
                            <Col className="ps-0">
                                <Link to="#" className="text-muted fw-bold">
                                    {selectedFile.name}
                                </Link>
                                <p className="mb-0">
                                    <strong>{selectedFile.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className="text-end">
                                <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                                    <i
                                        className="dripicons-cross"
                                        onClick={() => {
                                            props.onFileRemove && props.onFileRemove();
                                            removeFile(selectedFile);
                                        }}></i>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            <Dropzone
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles, props.onFileUpload)}
                multiple={props.multiple}>
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        <div className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <i className="h3 text-muted dripicons-cloud-upload"></i>
                            <h5>Drop files here or click to upload.</h5>
                            <span className="text-muted font-13">
                                (This is just a demo dropzone. Selected files are <strong>not</strong> actually
                                uploaded.)
                            </span>
                        </div>
                    </div>
                )}
            </Dropzone>

            {props.showPreview && selectedFiles.length > 0 && (
                <div className="dropzone-previews mt-3" id="uploadPreviewTemplate">
                    {(selectedFiles || []).map((f, i) => {
                        return (
                            <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        {f.preview && (
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                />
                                            </Col>
                                        )}
                                        {!f.preview && (
                                            <Col className="col-auto">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title bg-primary rounded">
                                                        {f.type.split('/')[0]}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        <Col className="ps-0">
                                            <Link to="#" className="text-muted fw-bold">
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                        <Col className="text-end">
                                            <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                                                <i className="dripicons-cross" onClick={() => removeFile(f)}></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export { FileUploader };
