import React, { ChangeEvent, forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

export type DatepickerInputProps = {
    onClick?: () => void;
    value?: string;
    inputClass: string;
    children?: React.ReactNode;
    placeHolder?: string;
    disabled?: boolean;
    buttonClass?: string;
};

/* Datepicker with Input */
export const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
    return (
        <input
            type="text"
            // className={props.inputClass ?? "form-control date"}
            // onClick={props.onClick}
            // value={props.value}
            // onChange={props.onChange}
            ref={ref}
            // placeholder={props?.placeHolder}
            // disabled={props?.disabled ?? false}
            {...props}
        />
    );
});

/* Datepicker with Addon Input */
export const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => (
    <div className="input-group" ref={ref} onClick={props.onClick} >
        <input
            type="text"
            className={`form-control form-control-light ${props?.inputClass}`}
            value={props.value}
            readOnly
            placeholder={props?.placeHolder}
            disabled={props?.disabled ?? false}
            style={{
                fontSize: '14px !important'
            }}

        />
        <div className="input-group-append">
            <span className={`${props.buttonClass ?? "input-group-text bg-primary text-white"}`} >
                <i className="mdi mdi-calendar-range font-13"></i>
            </span>
        </div>
    </div>
));

export type DateTypeOnChange = Date | undefined;

export type HyperDatepickerProps = {
    value: Date | undefined | null;
    onChange(date: DateTypeOnChange) : void;
    onChange(date: [DateTypeOnChange, DateTypeOnChange]) : void;
    hideAddon?: boolean;
    inputClass?: string;
    dateFormat?: string;
    minDate?: Date;
    minTime?: Date;
    maxDate?: Date;
    maxTime?: Date;
    className?: string;
    showTimeSelect?: boolean;
    tI?: number;
    timeFormat?: string;
    timeCaption?: string;
    showTimeSelectOnly?: boolean;
    showYearDropdown?: boolean;
    showMonthDropdown?: boolean;
    monthsShown?: number;
    inline?: boolean;
    isClearable?: boolean;
    selectsRange?: boolean;
    startDate?: Date | undefined | null,
    endDate?: Date | undefined | null;
    placeholderText?: string;
    showWeekNumbers?: boolean;
    disabled?: boolean;
};

const HyperDatepicker = (props: HyperDatepickerProps) => {
    // handle custom input
    const input =
        (props.hideAddon || false) === true ? (
            <DatepickerInput inputClass={`${props.inputClass!}`} placeHolder={props.placeholderText} disabled={props?.disabled ?? false}/>
        ) : (
            <DatepickerInputWithAddon inputClass={`${props.inputClass!}`} placeHolder={props.placeholderText} disabled={props?.disabled ?? false}/>
        );

    return (
        <>
            {/* date picker control */}
            <DatePicker
                isClearable={props.isClearable}
                customInput={input}
                timeIntervals={props.tI}
                className={classNames('form-control', props.inputClass)}
                selected={!props.selectsRange && props.value || undefined}
                onChange={(date: Date) => props.onChange(date!)}
                showTimeSelect={props.showTimeSelect}
                timeFormat={props.timeFormat ?? 'hh:mm a'}
                timeCaption={props.timeCaption}
                dateFormat={props.dateFormat ?? 'MM/dd/yyyy'}
                minDate={props.minDate} 
                minTime={props.minTime}
                maxDate={props.maxDate}
                maxTime={props.maxTime}
                monthsShown={props.monthsShown}
                showTimeSelectOnly={props.showTimeSelectOnly}
                showYearDropdown={props.showYearDropdown}
                showMonthDropdown={props.showMonthDropdown}
                scrollableMonthYearDropdown={true}
                inline={props.inline}
                autoComplete="off"

                selectsRange={props.selectsRange ?? false}
                startDate={props.selectsRange ? props?.startDate : undefined}
                endDate={props.selectsRange? props?.endDate : undefined}
                showWeekNumbers={props?.showWeekNumbers ?? undefined}
                disabled={props?.disabled ?? false}
            />
        </>
    );
};

export default HyperDatepicker;
