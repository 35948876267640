import {
  FilterValuesInterface,
  FullCoordinatesModel,
  MapActionTypes,
  MapError, MeasureUnitType,
  VehicleDetailsModel,
  VehicleInterface,
  VehicleStatisticsInterface
} from "./constants";
import {MapOptionsModel} from "../../pages/routing/fleet-view/complements/FleetUtils";

export const setMapLoading = (isLoading: boolean) => ({
  type: MapActionTypes.SET_LOADING,
  payload: {
    loading: isLoading,
  },
});

export const setMapIsMoving = (isMoving: boolean) => ({
  type: MapActionTypes.SET_MAP_IS_MOVING,
  payload: {
    mapIsMoving: isMoving,
  },
});

export const setMapError = (error: MapError) => ({
  type: MapActionTypes.SET_ERROR,
  payload: {
    error
  },
});

/** ----------------------------------------------------------  **/
export const setMarkerTrackingId = (trackingId: string | null) => ({
    type: MapActionTypes.SET_MARKER_TRACKING_ID,
    payload: {
      trackingId: trackingId,
    },
})

export const setFleetMapOptions = (fleetMapOptions: MapOptionsModel | null) => ({
  type: MapActionTypes.SET_MAP_FLEET_OPTIONS,
  payload: {
    fleetMapOptions: fleetMapOptions,
  },
})

export const setZoomLevel = (zoomLevel: number | null) => ({
  type: MapActionTypes.SET_ZOOM_LEVEL,
  payload: {
    zoomLevel: zoomLevel,
  },
})

export const setVehicleList = (vehicleList: VehicleInterface[] ) => ({
  type: MapActionTypes.SET_VEHICLE_LIST,
  payload: {
    vehicleList: vehicleList,
  },
})

export const setVehicleListStatistics = (vehicleListStatistics: VehicleStatisticsInterface ) => ({
  type: MapActionTypes.SET_VEHICLE_LIST_STATISTICS,
  payload: {
    vehicleListStatistics,
  },
})

export const setVehicleId = (vehicleId: string ) => ({
  type: MapActionTypes.SET_VEHICLE_ID,
  payload: {
    vehicleId: vehicleId,
  },
})

export const setVehicleDetails = (vehicle: VehicleDetailsModel ) => ({
  type: MapActionTypes.SET_VEHICLE_DETAILS,
  payload: {
    vehicleDetails: vehicle,
  },
})

export const setTasksDetails = (tasks: any[] ) => ({
  type: MapActionTypes.SET_TASKS_DETAILS,
  payload: {
    tasksDetails: tasks,
  },
})

export const setUncompletedTasksDetails = (tasks: [] ) => ({
  type: MapActionTypes.SET_UNCOMPLETED_TASKS_DETAILS,
  payload: {
    uncompletedTasksDetails: tasks,
  },
})

export const setTasksMarkers = (markers: [] ) => ({
  type: MapActionTypes.SET_TASKS_MARKERS,
  payload: {
    tasksMarkers: markers,
  },
})

export const setTasksIDList = (trackingIdTaskList: string) => ({
  type: MapActionTypes.SET_TASKS_ID_LIST,
  payload: {
    taskIdList: trackingIdTaskList,
  },
})

export const setTasksOutcomeList = (trackingOutcomeTaskList: string) => ({
  type: MapActionTypes.SET_TASKS_OUTCOME_LIST,
  payload: {
    taskOutcomeList: trackingOutcomeTaskList,
  },
})

export const setUpdateMarkersLabel = (update: boolean) => ({
  type: MapActionTypes.SET_UPDATE_MARKERS_LABEL,
  payload: {
    updateMarkersLabel: update,
  },
})

export const setFleetMarkerVehicleId = (vehicleId: string | null) => ({
  type: MapActionTypes.SET_FLEET_MARKER_VEHICLE_ID,
  payload: {
    fleetMarkerVehicleId: vehicleId,
  },
})

export const setTaskMarkerVehicleId = (vehicleId: string | null) => ({
  type: MapActionTypes.SET_TASK_MARKER_VEHICLE_ID,
  payload: {
    taskMarkerVehicleId: vehicleId,
  },
})

export const setFleetMetroSelectionCoordinates = (fullCoordinates: FullCoordinatesModel) => ({
  type: MapActionTypes.SET_MAP_COORDINATE_VALUES,
  payload: {
    mapCoordinateValues: fullCoordinates,
  },
})

export const setIsDefaultCenteredMap = (value: boolean | null) => ({
  type: MapActionTypes.SET_IS_DEFAULT_CENTERED_MAP,
  payload: {
    isDefaultCenteredMap: value,
  },
})

export const setShowOutcomeMarkersOnDetails = (value: boolean | null) => ({
  type: MapActionTypes.SET_SHOW_OUTCOME_MARKERS_ON_DETAILS,
  payload: {
    showOutcomeMarkersOnDetails: value,
  },
})

export const setToggleMeasureUnit = (value: MeasureUnitType | null) => ({
  type: MapActionTypes.SET_TOGGLE_MEASURE_UNIT,
  payload: {
    toggleMeasureUnit: value,
  },
})

export const setFleetViewFilters = (filterValues: FilterValuesInterface) => ({
  type: MapActionTypes.SET_FILTER_VALUES,
  payload: {
    filterValues: filterValues,
  },
})