import ReactSelect, { GroupBase, Props } from "react-select";

const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>) => {
  return (
    <ReactSelect
      className="bt-react-select"
      classNamePrefix="bt-react-select"
      {...props}
    />
  )
}

export default Select;