import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TableInstance } from 'react-table';

export type PageSize = {
    text: string;
    value: number;
};

type PaginationProps = {
    tableProps: TableInstance;
    sizePerPageList: PageSize[];
    onChangePage?: (value: string) => void;
    onChangePageSize?: (value: string) => void;
};

const Pagination = ({ tableProps, sizePerPageList, onChangePage, onChangePageSize }: PaginationProps) => {
    /**
     * pagination count , index
     */
    const [pageCount, setPageCount] = useState<number>(tableProps?.pageCount || 0);
    const [pageIndex, setPageIndex] = useState<string>(tableProps?.state.pageIndex.toString() || '0');

    useEffect(() => {
        setPageCount(tableProps?.pageCount || 0);
        setPageIndex(tableProps?.state.pageIndex.toString() || '0');
    }, [tableProps?.pageCount, tableProps?.state.pageIndex]);

    /**
     * get filter pages
     */
    const filterPages = useCallback(
        (visiblePages: number[], totalPages: number) => {
            return visiblePages.filter((page: number) => page <= pageCount);
        },
        [pageCount]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page: number, total: number) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6], total);
            } else {
                if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                    return [1, page - 1, page, page + 1, total];
                } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    const activePage: number = useMemo(() => {
        return Number(pageIndex) + 1;
    } , [pageIndex]);
    /**
     * handle page change
     * @param page - current page
     * @returns
     */
    const changePage = (pageNumber: string) => {
        const page = Number(pageNumber);

        if (page === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(page, pageCount);
        setVisiblePages(filterPages(visiblePages, pageCount));

        tableProps?.gotoPage(page - 1);

        setPageIndex(pageNumber);
        const pageToChange = page - 1;
        if (pageToChange > (tableProps?.pageCount || 0)) return;
        if (pageToChange < 0) return;

        onChangePage && onChangePage(pageToChange.toString());
    };

    useEffect(() => {
        const visiblePages = getVisiblePages(0, pageCount);
        setVisiblePages(visiblePages);
    }, [pageCount, getVisiblePages]);

    const [visiblePages, setVisiblePages] = useState<number[]>(getVisiblePages(0, pageCount));

    return (
        <div className='d-lg-flex align-items-center text-center pb-1'>
            {sizePerPageList.length > 0 && (
                <div className="d-inline-block">
                    <label className="me-1">Display :</label>
                    <select
                        value={tableProps?.state.pageSize}
                        onChange={(e) => {
                            onChangePageSize && onChangePageSize(e.target.value);
                            tableProps?.setPageSize(Number(e.target.value));
                        }}
                        className="form-select d-inline-block w-auto">
                        {(sizePerPageList || []).map((pageSize, index) => {
                            return (
                                <option key={index.toString()} value={pageSize.value}>
                                    {pageSize.text}
                                </option>
                            );
                        })}
                    </select>
                    <span className="mx-1">
                        of <strong>{tableProps?.totalCount} </strong>items.
                    </span>
                </div>
            )}

            {tableProps?.pageOptions?.length ? (
                <span className="me-3">
                    Page{' '}
                    <strong>
                        {activePage} of {tableProps?.pageOptions?.length}
                    </strong>{' '}
                </span>
            ) : (
                <span className="me-3">
                    <strong>No result found</strong>{' '}
                </span>
            )}

            <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
                <label>Go to page : </label>
                <input
                    type="number"
                    value={pageIndex ? Number(pageIndex) + 1 : pageIndex}
                    min="1"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if(parseInt(e.target.value)>tableProps?.pageOptions?.length){
                            return false
                        }
                        changePage(e.target.value);
                    }}
                    className="form-control w-25 ms-1 d-inline-block"
                />
            </span>

            <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
                <li
                    key="prevpage"
                    className={classNames('page-item', 'paginate_button', 'previous', {
                        disabled: activePage <= 1,
                    })}
                    onClick={() => {
                        if (activePage <= 1) return;
                        changePage((activePage - 1).toString());
                    }}>
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-left"></i>
                    </Link>
                </li>

                {(visiblePages || []).map((page, index, array) => {
                    return array[index - 1] + 1 < page ? (
                        <React.Fragment key={page.toString()}>
                            <li className="page-item disabled d-none d-xl-inline-block">
                                <Link to="#" className="page-link">
                                    ...
                                </Link>
                            </li>
                            <li
                                className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                    active: activePage === page,
                                })}
                                onClick={(e) => {
                                    changePage(page.toString());
                                }}>
                                <Link to="#" className="page-link">
                                    {page}
                                </Link>
                            </li>
                        </React.Fragment>
                    ) : (
                        <li
                            key={page.toString()}
                            className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                active: activePage === page,
                            })}
                            onClick={(e) => {
                                changePage(page.toString());
                            }}>
                            <Link to="#" className="page-link">
                                {page}
                            </Link>
                        </li>
                    );
                })}

                <li
                    key="nextpage"
                    className={classNames('page-item', 'paginate_button', 'next', {
                        disabled: activePage >= tableProps?.pageCount,
                    })}
                    onClick={() => {
                        if (activePage >= tableProps?.pageCount) return;
                        changePage((activePage + 1).toString());
                    }}>
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export { Pagination };
