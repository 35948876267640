import { BTAction } from 'redux/constants';
import { LayoutActionTypes } from './constants';

export const changeLayout = (layout: string): BTAction<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT,
    payload: layout,
});

export const changeLayoutColor = (color: string): BTAction<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
    payload: color,
});

export const changeLayoutWidth = (width: string): BTAction<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeSidebarTheme = (sidebarTheme: string): BTAction<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
    payload: sidebarTheme,
});

export const changeSidebarType = (sidebarType: string): BTAction<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
});

export const showRightSidebar = (): BTAction<null> => ({
    type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
    payload: null,
});

export const hideRightSidebar = (): BTAction<null> => ({
    type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
    payload: null,
});
