const AUTH_KEY = 'user';
const TRACKING_ID = 'trackingId';
const TASK_VEHICLE_ID = 'taskVehicleId';
const PREVIOUS_TRACKING_ID = 'previousTrackingId';
const REMAINING_SEGMENTS = 'remainingSegments';
const VEHICLE_TRACKING_ID = 'vehicleTrackingId';
const OKTA_EXCEPTION_MESSAGE = 'oktaExceptionMessage';

export const TOGGLE_OUTCOME_MARKERS = 'toggleOutcomeMarkers';
export const TOGGLE_CLUSTERIZE_MARKERS = 'toggleClusterizeMarkers';
export const TOTAL_VEHICLE_TASKS = 'totalVehicleTasks';

const getUserFromStorage = () => {
    const user = localStorage.getItem(AUTH_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class LocalStorage {
    setLoggedInUser = (data: any) => {
        if (data) localStorage.setItem(AUTH_KEY, JSON.stringify(data));
        else {
            localStorage.removeItem(AUTH_KEY);
        }
    };
    
    setOktaExceptionMessage = (data: any) => localStorage.setItem(OKTA_EXCEPTION_MESSAGE, JSON.stringify(data))
    getOktaExceptionMessage = () => JSON.parse(localStorage.getItem(OKTA_EXCEPTION_MESSAGE)!)

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromStorage();
    };

    setUserInStorage = (modifiedUser: any) => {
        let userInfo = localStorage.getItem(AUTH_KEY);
        
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };

    // FLEET VIEW
    /* Tracking ID */
    setMarkerTrackingId = (id: string) => {
        localStorage.setItem(PREVIOUS_TRACKING_ID, this.getMarkerTrackingId());
        localStorage.setItem(TRACKING_ID, this.getMarkerTrackingId() === id ? '' : id);
    }

    getMarkerTrackingId = () => {
        return localStorage.getItem(TRACKING_ID) || '';
    };

    /* Task vehicle ID */
    setTaskVehicleTrackingId = (id: string) => {
        localStorage.setItem(TASK_VEHICLE_ID, this.getTaskVehicleTrackingId() === id ? '' : id);
    }

    getTaskVehicleTrackingId = () => {
        return localStorage.getItem(TASK_VEHICLE_ID) || '';
    };

    /* Marker triggered */
    setRemainingSegments = (remainingSegments: any[]) => {
        localStorage.setItem(REMAINING_SEGMENTS, JSON.stringify(remainingSegments));
    }

    getRemainingSegments = () => {
        return JSON.parse(localStorage.getItem(REMAINING_SEGMENTS) || '') || [];
    };

    /* Previous Tracking ID */
    getPreviousMarkerTrackingId = () => {
        return localStorage.getItem(PREVIOUS_TRACKING_ID) || '';
    };

    /* Vehicle ID */
    setVehicleTrackingId = (id: string) => {
        localStorage.setItem(VEHICLE_TRACKING_ID, this.getVehicleTrackingId() === id ? '' : id);
    }

    getVehicleTrackingId = () => {
        return localStorage.getItem(VEHICLE_TRACKING_ID) || '';
    };

    /* Outcome Markers */
    setToggleOutcomeMarkers = (toggle: boolean) => {
        localStorage.setItem(TOGGLE_OUTCOME_MARKERS, JSON.stringify(toggle));
    }

    getToggleOutcomeMarkers = () => {
        return JSON.parse(localStorage.getItem(TOGGLE_OUTCOME_MARKERS) || 'false');
    };

    /* Clusterize Markers */
    setToggleClusterizeMarkers = (toggle: boolean) => {
        localStorage.setItem(TOGGLE_CLUSTERIZE_MARKERS, JSON.stringify(toggle));
    }

    getToggleClusterizeMarkers = () => {
        return JSON.parse(localStorage.getItem(TOGGLE_CLUSTERIZE_MARKERS) ?? 'true');
    };

    setVehicleTotalTasks = (total: number) => {
        localStorage.setItem(TOTAL_VEHICLE_TASKS, JSON.stringify(total));
    }

    getVehicleTotalTasks = () => {
        return JSON.parse(localStorage.getItem(TOTAL_VEHICLE_TASKS) || '0');
    };
}

export default new LocalStorage();
