import LocalStorage from 'helpers/api/LocalStorage';
import SessionStorage from 'helpers/api/LocalStorage';
import { Reducer } from 'redux';
import { BTAction } from 'redux/constants';
import { AuthActionTypes } from './constants';

type UserData = {
    id: string;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    roles: string[];
    groups: string[];
    token: string;
    message: string;
    imageUrl?: string;
};

type AuthActionPayload = {
    actionType?: string;
    data: UserData | null;
    authorization: { token: string | null };
    error: string | null;
    isAuthed: boolean;
    isActive: boolean;
    user?: UserData; // Add user property
    token?: string; // Add token property
};

type State = {
    user: UserData | null;
    loading?: boolean;
    value: boolean | null;
    userLoggedIn: boolean | null;
    error: string | null;
    userSignUp: boolean | null;
    userLogout: boolean | null;
    passwordReset: boolean | null;
    registerError: string | null;
    passwordChange: boolean | null;
    resetPasswordSuccess: UserData | null;
    authorization: {
        token: string | null;
    };
    isAuthed: boolean;
    isActive: boolean;
};

const INIT_STATE: State = {
    user: SessionStorage.getLoggedInUser(),
    loading: false,
    userLoggedIn: false,
    error: null,
    passwordChange: null,
    passwordReset: null,
    registerError: null,
    resetPasswordSuccess: null,
    userLogout: null,
    userSignUp: null,
    value: null,
    authorization: {
        token: null,
    },
    isAuthed: false,
    isActive: false,
};

const Auth: Reducer<State, BTAction<AuthActionPayload>> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AuthActionTypes.SET_USER_AUTHENTICATION:
            let oldUser = LocalStorage.getLoggedInUser();
            // console.log(oldUser)
            oldUser.token = action.payload.authorization.token;
            LocalStorage.setLoggedInUser(oldUser);
            return {
                ...state,
                authorization: { ...action.payload.authorization },
            };

        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        userSignUp: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordReset: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                default:
                    return { ...state };
            }

        // case AuthActionTypes.LOGIN_USER || AuthActionTypes.OAUTH_LOGIN_USER:
        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };

        case AuthActionTypes.OAUTH_LOGIN_USER: {
            return {
                ...state,
                user: action?.payload?.user ?? null, // Correctly set the user from action payload
                authorization: { token: action?.payload?.token ?? null }, // Set the token from action payload
                userLoggedIn: true,
                loading: false,
            };
        }

        case AuthActionTypes.SIGNUP_USER:
            return { ...state, loading: true, userSignUp: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.RESET:
            LocalStorage.setLoggedInUser(null);
            return {
                ...state,
                loading: false,
                error: null,
                userSignUp: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
                user: null,
                authorization: { token: null },
            };
        case AuthActionTypes.UPDATE_IS_AUTHED:
            return {
                ...state,
                isAuthed: action.payload.isAuthed,
            };
        case AuthActionTypes.UPDATE_IS_ACTIVE:
            return {
                ...state,
                isActive: action.payload.isActive,
            };
        default:
            return { ...state };
    }
};

export default Auth;
