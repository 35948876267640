import { useIdleTimer } from 'react-idle-timer'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { store } from 'redux/store';

interface  InactivityParametersType {
    timeout: number,
    promptBeforeIdle: number,
  };

const Inactivity: React.FC<InactivityParametersType> = ( {timeout, promptBeforeIdle}) => {

    const [remaining, setRemaining] = useState<number>(timeout)
    const [open, setOpen] = useState<boolean>(false)
    const state = store.getState();

    const onIdle = () => {
        if (state.Auth.isActive) {
            window.location.href = "/account/logout"
        }
        setOpen(false)
    }

    const onActive = () => {
        setOpen(false)
    }

    const onPrompt = () => {
        setOpen(true)
    }

    const { getRemainingTime, activate, } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    return (<Modal
        show={open && state.Auth.isActive}
        backdrop="static"
        keyboard={false}
        onHide={activate}>
        <Modal.Header closeButton>
            <Modal.Title>Session warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Logging out in {remaining} {seconds}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onIdle}>Logout</Button>
        </Modal.Footer>
    </Modal>);
};

export default Inactivity;
