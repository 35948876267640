interface IExpression {
  regex: RegExp;
  message: string;
}

interface IRegexExpression {
  alphaNumeric: IExpression,
  email: IExpression,
  phone: IExpression,
  alpha: IExpression,
  numeric: IExpression,
}

const RegexExpressions: IRegexExpression = {
  alphaNumeric: {
    regex: /^[a-zA-Z0-9_ .-]*$/,
    message: "This should be an alpha numeric field",
  },
  email: {
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: "This should be an email",
  },
  phone: {
    regex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    message: "This should be a phone number (ex: 555-555-5555)",
  },
  alpha: {
    regex: /^[a-zA-Z_ .-]*$/,
    message: "This should be only an alphabetical field",
  },
  numeric: {
    regex: /^[0-9_.-]*$/,
    message: "This should be only a numeric field",
  }
}

export default RegexExpressions;