import { MDIIcon } from "pages/icons/MDIIcons";

/**
 * Renders the preloader
 */
export const Loader = () => {
    return (
        <div className="preloader" id="preloader">
            <div className="status" id="status">
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export const LoaderSpin = () => {
  return (
    <div className='full-loader'>
        <MDIIcon icon="loading" size={48} className="primary-color mdi-spin" />
    </div>
  )
}
