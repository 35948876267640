import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';
import { Loader } from 'components';
import { initDomains } from 'redux/actions';
import { useRedux } from 'hooks';

interface ProtectedRouteProps {
    component: React.ComponentType<any>;
    roles: string[];
    loggedInUser: User | undefined | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, roles, loggedInUser }) => {
    const { appSelector, dispatch } = useRedux();
    const [hasRequiredRole, setHasRequiredRole] = useState<boolean | null>(null); // Use null for "unknown" state

    const { domains, authToken } = appSelector((state) => ({
        domains: state.Domains.list,
        authToken: state.Auth.authorization.token,
    }));

    useEffect(() => {
        if (!loggedInUser) {
            setHasRequiredRole(null); // User not loaded yet
            return;
        }

        const rolesClaimName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
        const userRoles = loggedInUser?.[rolesClaimName] || [];
        const hasRole = roles.some((role) => userRoles.includes(role));

        setHasRequiredRole(hasRole);
    }, [loggedInUser, roles]);

    if (loggedInUser === null) {
        // If user data is still loading, show a loading indicator
        return <div>Loading...</div>;
    }

    if (hasRequiredRole === null) {
        // If the role-checking is still pending (due to user data), show a loading indicator
        return <div>Loading...</div>;
    }

    // init domains
    if (!domains && hasRequiredRole) {
        initDomains();
    }

    return hasRequiredRole ? <Component /> : <Navigate to="/access/denied" />;
};

export default ProtectedRoute;
