import classNames from 'classnames';
import HyperDatepicker, { HyperDatepickerProps } from 'components/Datepicker';
import Select from 'components/Select';
import { useToggle } from 'hooks';
import { InputHTMLAttributes, ReactElement, ReactNode, useMemo } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Control, FieldError, FieldErrors, RegisterOptions } from 'react-hook-form';
import { GroupBase, MultiValue, OptionsOrGroups, Props as SelectProps, PropsValue, SingleValue } from 'react-select';
import MaskedInput, { Mask } from 'react-text-mask';
import { JsxElement } from 'typescript';
import { InputTypes, SelectOption } from 'utils';
import RegexExpressions from 'utils/RegexExpressions';
import TagInput from './TagInput';

export type InputValidation = {
    type?: keyof typeof RegexExpressions;
} & RegisterOptions;

type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    register?: any;
    label?: string | ReactNode | ReactElement | JsxElement;
    containerAs?: 'row' | 'col';
    type?: InputTypes;
    placeholder?: string;
    prefix?: string | ReactNode | ReactElement | JsxElement;
    suffix?: string | ReactNode | ReactElement | JsxElement;
    errors?: FieldErrors;
    control?: Control<any>;
    className?: string;
    labelClassName?: string;
    containerClass?: string;
    InputGroupClassname?: string;
    refCallback?: any;
    children?: React.ReactNode;
    rows?: string;
    validation?: InputValidation;
    // date options
    dateValue?: Date | null;
    dateProps?: Omit<HyperDatepickerProps, 'onChange' | 'value'>;
    isClearable?: boolean;
    // select options
    options?: OptionsOrGroups<SelectOption, GroupBase<SelectOption>> | undefined;
    setValue?: Function;
    selectValue?: string | string[] | number | number[] | null;
    selectStyle?: {
        isMulti?: boolean;
        hideDropDownIndicator?: boolean;
        maxMenuHeight?: SelectProps['maxMenuHeight'];
        minMenuHeight?: SelectProps['minMenuHeight'];
    };
    mask?: Mask;
};

const FormInput = ({
    label,
    type,
    name,
    containerAs = 'col',
    placeholder,
    prefix,
    suffix,
    register,
    errors,
    control,
    className,
    labelClassName,
    containerClass,
    InputGroupClassname,
    refCallback,
    children,
    rows,
    validation,
    dateValue,
    dateProps,
    options,
    setValue,
    selectValue,
    selectStyle,
    mask,
    isClearable = true,
    ...otherProps
}: FormInputProps) => {
    // handle errors
    const getError = () => {
        if (!errors) {
            return null;
        }

        let params: string[] = [];
        if (name.includes('.')) {
            params = name.split('.');
        }

        let fieldError: any = errors;
        if (params && params.length > 0) {
            params.forEach((param) => {
                if (!fieldError) return undefined;

                fieldError = fieldError[param];
            });
            if (!fieldError) return undefined;
            return fieldError;
        } else {
            if (!fieldError || !fieldError[name]) return undefined;
            return fieldError[name];
        }
    };
    const fieldError: FieldError = getError();

    if (validation?.type) {
        const expression = RegexExpressions[validation.type];
        validation.pattern = {
            value: expression.regex,
            message: expression.message,
        };
    }

    // register input to form
    const registration = register
        ? register(name, validation)
        : {
            name: name,
            ref: (r: HTMLInputElement) => {
                if (refCallback) refCallback(r);
            },
        };

    /** Select Handler Start **/
    // filter select values selected
    const internalSelectValue: PropsValue<SelectOption> | undefined = useMemo(() => {
        const opts = options as Array<SelectOption>;
        if (!opts) return undefined;

        if (selectValue !== undefined && selectValue !== null) {
            if (selectStyle?.isMulti && Array.isArray(selectValue)) {
                return opts.filter((opt) => opt.value && selectValue.findIndex((value) => value === opt.value) !== -1);
            }

            return opts?.find((opt) => opt.value === selectValue);
        }
        return null;
    }, [selectValue, options, selectStyle?.isMulti]);

    const onChangeSelect = (
        option: SingleValue<string | number | SelectOption> | MultiValue<string | number | SelectOption>
    ) => {
        if (selectStyle?.isMulti && setValue && Array.isArray(option)) {
            setValue(
                name,
                option.map((opt) => opt.value)
            );
        } else if (setValue) {
            const opt = option as SingleValue<SelectOption>;
            setValue(name, opt?.value);
        }
    };
    /** Select Handler End **/

    // handle input type
    const [showPassword, togglePassword] = useToggle();
    let compType: any = type;
    let comp: any = 'input';
    let Element = children ? children : null;
    let showLabel = true;
    switch (type) {
        case 'textarea':
            comp='textarea';
            break;

        case 'hidden':
            Element = <input type={type} {...registration} {...otherProps} />;
            break;

        case 'password':
            comp = 'input';
            Element = null;
            break;

        case 'select':
            comp = 'div';
            Element = (
                <Select
                    isClearable={isClearable}
                    isDisabled={otherProps.disabled}
                    options={options}
                    value={internalSelectValue}
                    placeholder={placeholder}
                    isMulti={selectStyle?.isMulti}
                    maxMenuHeight={selectStyle?.maxMenuHeight}
                    minMenuHeight={selectStyle?.minMenuHeight}
                    components={{
                        ...(selectStyle?.hideDropDownIndicator && { DropdownIndicator: () => null }),
                    }}
                    {...otherProps}
                    onChange={onChangeSelect}
                    styles={{
                        control: (props: any) => ({
                            ...props,
                            border: 'none !important',
                        }),
                    }}
                />
            );
            break;

        case 'checkbox':
            comp = 'div';
            showLabel = false;
            Element = (
                <Form.Check
                    type="checkbox"
                    label={<label className="ms-1">{label}</label>}
                    id={`${name}-${(Math.random() * 10000).toFixed(0)}`}
                    className={className}
                    isInvalid={errors && errors[name] ? true : false}
                    {...registration}
                    {...otherProps}
                />
            );
            break;

        case 'radio':
            comp = 'div';
            showLabel = false;
            Element = (
                <Form.Check
                    type="radio"
                    label={label}
                    id={`${name}-${(Math.random() * 10000).toFixed(0)}`}
                    className={className}
                    isInvalid={errors && errors[name] ? true : false}
                    {...registration}
                    {...otherProps}
                />
            );
            break;

        case 'date':
            comp = 'div';
            Element = (
                <HyperDatepicker
                    isClearable
                    {...otherProps}
                    onChange={(date) => setValue && setValue(name, date)}
                    value={dateValue !== undefined ? dateValue : null}
                    {...dateProps}
                />
            );
            break;

        case 'switch':
            comp = 'div';
            Element = (
                <Form.Check
                    type="switch"
                    id={`${name}-${(Math.random() * 10000).toFixed(0)}`}
                    className={`form-switch ${className}`}
                    isInvalid={errors && errors[name] ? true : false}
                    {...registration}
                    {...otherProps}
                />
            );
            break;

        case 'taginput':
            comp = 'div';
            Element = (
                <TagInput
                    value={otherProps.value ? (otherProps.value as string) : ''}
                    onChange={(value) => setValue && setValue(name, value)}
                    border="none"
                />
            );
            break;

        case 'phone':
            comp = 'div';
            Element = (
                <MaskedInput
                    className="form-control"
                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder={placeholder ? placeholder : '___-___-____'}
                    style={{
                        border: 'none',
                    }}
                    {...registration}
                />
            );
            break;

        case 'maskedinput':
            comp = 'div';
            Element = (
                <MaskedInput
                    className="form-control"
                    mask={mask || []}
                    placeholder={placeholder}
                    style={{
                        border: 'none',
                    }}
                    {...registration}
                />
            );
            break;

        default:
            break;
    }

    return (
        <>
            <Form.Group
                className={`${containerClass} ${containerAs === 'row' && 'align-items-center'} ${fieldError?.message ? 'position-relative' : ''
                    }`}
                as={containerAs === 'row' ? Row : Col}>
                {label && showLabel && (
                    <Form.Label
                        className={`${containerAs === 'row' ? 'col col-auto m-0' : ''
                            } ${labelClassName} card-form-label`}>
                        {label}
                        {validation?.required && <span style={{ color: 'red' }}>*</span>}
                    </Form.Label>
                )}

                <InputGroup as={containerAs === 'row' ? Col : undefined} className={InputGroupClassname}>
                    {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
                    <Form.Control
                        type={showPassword ? 'text' : compType}
                        placeholder={placeholder}
                        id={`form-input-${name}`}
                        as={comp}
                        isInvalid={errors && fieldError ? true : false}
                        {...(compType !== 'select' && registration)}
                        {...otherProps}
                        autoComplete={name}
                        rows={rows}
                        style={{
                            ...(comp === 'div' && {
                                paddingLeft: '0px',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                paddingRight: errors && fieldError ? '2.5rem' : '0px',
                            }),
                            ...((compType === 'radio' || compType === 'checkbox' || compType === 'switch') && {
                                border: 'none',
                            }),
                            ...(otherProps.readOnly && {
                                border: 'none',
                                background: 'transparent',
                            }),
                        }}>
                        {Element ? Element : null}
                    </Form.Control>
                    {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}

                    {compType === 'password' && (
                        <div
                            className={classNames('input-group-text', 'input-group-password', {
                                'show-password': showPassword,
                            })}
                            data-password={showPassword ? 'true' : 'false'}>
                            <span className="password-eye" onClick={togglePassword}></span>
                        </div>
                    )}
                </InputGroup>

                {errors && fieldError && fieldError.message && (
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                        {fieldError.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default FormInput;
