import { Row, Col, Breadcrumb } from 'react-bootstrap';

type BreadcrumbItems = {
    label: string;
    path: string;
    active?: boolean;
};

type EstilosAdicionales = {
    [key: string]: string | number;
};

type PageTitleProps = {
    title: string | JSX.Element;
    breadCrumbItems?: Array<BreadcrumbItems>;
    showBackBtn?: boolean;
    backBtnClick?: Function;
    customStyle?: EstilosAdicionales
};

const PageTitle = ({ breadCrumbItems, title, showBackBtn, backBtnClick, customStyle }: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {showBackBtn && (
                        <i
                            className="dripicons-arrow-thin-left"
                            onClick={() => backBtnClick && backBtnClick()}
                            style={{
                                marginRight: '10px',
                                borderRadius: '25px',
                                padding: '3px 6px 3px 6px',
                                ...customStyle
                        }}></i>
                    )}
                    <div className="page-title-box" style={{ width: '100%' }}>
                        <div className="page-title-right">
                            {breadCrumbItems && (
                              <Breadcrumb listProps={{ className: 'm-0 fs-5' }}>
                                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                                  {breadCrumbItems.map((item, index) => {
                                      return item.active ? (
                                        <Breadcrumb.Item
                                          active
                                          key={index.toString()}
                                          className="breadcrumb-endpoint">
                                            {item.label}
                                        </Breadcrumb.Item>
                                      ) : (
                                        <Breadcrumb.Item key={index.toString()} href={item.path}>
                                            {item.label}
                                        </Breadcrumb.Item>
                                      );
                                  })}
                              </Breadcrumb>
                            )}
                        </div>
                        {typeof title == 'string' ? <h4 className="page-title">{title}</h4> : title}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
