import styled from "@emotion/styled";
import { MDIIcon } from "pages/icons/MDIIcons";
import React, { CSSProperties, useMemo, useState } from "react";

const Input = styled.input({
  ":focus-visible": {
    outline: "none",
  }
});

const CloseButton = styled.div({
  ":hover": {
    color: "red",
  }
});

export interface Props {
  value: string;
  onChange: (value: string) => void;
  border?: CSSProperties["border"],
}

const TagInput = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ",") {
      const val = `${props.value}${props.value && ","}${inputValue.replace(",", "")}`;
      props.onChange(val);
      setInputValue("");
      return;
    }

    if (event.key === "Backspace" && !inputValue) {
      const val = props.value.split(",");
      if (val) {
        const ret = val.splice(0, val.length - 1).toString();
        console.log(ret);
        props.onChange(ret);
        return;
      }
    }
  }

  const onRemoveTag = (text: string) => {
    const array = props.value.split(",");
    const index = array.findIndex((val) => val === text);
    array.splice(index, 1);
    const ret = array.toString();
    props.onChange(ret);
  }

  const splitted = useMemo(() => {
    return props.value?.split(",");
  }, [props.value]);
  return (
    <div
      className="d-flex rounded flex-wrap h-100 w-100"
      style={{
        padding: "0.45rem 0.9rem",
        border: props.border || "1px solid #dee2e6",
      }}
    >
      {splitted?.map((val) => {
        if (!val) return null;
        return (
          <div
            key={`tagInput-${val}-${(Math.random() * 10000).toFixed()}`}
            className="d-flex border px-1"
            style={{
              backgroundColor: "#3C8DBC",
              color: "white",
              borderRadius: "30px",
              margin: "2px",
            }}
          >
            {val}
            <CloseButton className="ms-1" onClick={() => onRemoveTag(val)}>
              <MDIIcon icon="close" />
            </CloseButton>
          </div>
        );
      })}
      <Input
        className="border-0 rounded flex-fill"
        value={inputValue}
        onChange={(event) => setInputValue(event.currentTarget.value)}
        onKeyUp={onKeyUp}
      />
    </div>
  )
}

export default TagInput;