import { useTranslation } from 'react-i18next';
import AccountLayout from './AccountLayout';


export type LogoutParameters = {
    url?: String;
  }

const Redirecting = () => {
    const { t } = useTranslation();
    
    return (
        <AccountLayout>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('Redirecting')}</h4>
            </div>
        </AccountLayout>
    );
};

export default Redirecting;
