import { combineReducers } from "redux";
import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Application from "./application/reducers";
import Domains from "./domains/reducers";
import FleetView from "./fleetView/reducers";

export default combineReducers({
    Auth,
    Layout,
    Application,
    Domains,
    FleetView,
});