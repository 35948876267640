import { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { CSSObject } from "@emotion/react";
import { MDIICONS_LIST } from './data';

export type MDIIconsList = typeof MDIICONS_LIST[number]["name"];

type IconProps = {
    color?: CSSObject["color"],
    onClick?: Function,
    verticalAlign?: CSSProperties["verticalAlign"]
}

const Icon = styled.i<IconProps>((props: IconProps) => ({
    color: props.color?.toString(),
    cursor: props.onClick && "pointer",

   "::before": {
        verticalAlign: props.verticalAlign,
   } 
}));

interface Props {
    icon: MDIIconsList,
    size?: 14 | 16 | 18 | 20 | 22 | 24 | 36 | 48;
    rotate?: string | number,
    spin?: boolean,
    color?: CSSProperties["color"],
    className?: string,
    title?: string,
    onClick?: (a?: any) => any | void,
    verticalAlign?: "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom",
    style?: CSSProperties
}

export const MDIIcon = (props: Props) => {
    const icon = `mdi-${props.icon}`;
    const size = `mdi-${props.size}px`;
    const spin = props.spin ? "mdi-spin" : "";
    const rotate = `mdi-rotate-${props.rotate}`;

    const localClass = props.className;

    const className = `mdi ${icon} ${size} ${spin} ${rotate} ${localClass} d-inline-flex`
    return (
        <Icon
            onClick={props.onClick}
            className={className}
            color={props.color}
            verticalAlign={props.verticalAlign || "middle"}
            title={props.title}
            style={props.style}
        />
    )
}
