import { Reducer } from 'redux';
import { BTAction } from 'redux/constants';
import { DomainActionTypes, DomainPayload, DomainState } from './constants';

const initialState: DomainState = {
    list: null,
};

const reducer: Reducer<DomainState, BTAction<DomainPayload>> = (state = initialState, action) => {
    switch (action.type) {
        case DomainActionTypes.UPDATE_CITIES_DOMAIN:
        case DomainActionTypes.UPDATE_COMPANIES_DOMAIN:
        case DomainActionTypes.UPDATE_ROUTING_PARTIES_DOMAIN:
        case DomainActionTypes.UPDATE_COUNTRIES_DOMAIN:
        case DomainActionTypes.UPDATE_METRO_CODES_DOMAIN:
        case DomainActionTypes.UPDATE_METRO_CODEIDS_DOMAIN:
        case DomainActionTypes.UPDATE_METRO_ZONES_DOMAIN:
        case DomainActionTypes.UPDATE_ONFLEET_TEAMS_DOMAIN:
        case DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN:
        case DomainActionTypes.UPDATE_STATES_DOMAIN:
        case DomainActionTypes.UPDATE_MERCHANTS_DOMAIN:
        case DomainActionTypes.UPDATE_CURRENCIES_DOMAIN:
        case DomainActionTypes.UPDATE_ZIP_CODE_EXCEPTION_TYPES:
        case DomainActionTypes.UPDATE_USER_ROLES:
        case DomainActionTypes.UPDATE_ZIP_CODES:
        case DomainActionTypes.UPDATE_CLAIM_STATUS:
        case DomainActionTypes.UPDATE_FAILURE_REASON:
        case DomainActionTypes.UPDATE_EVENT_TYPES:
        case DomainActionTypes.UPDATE_NOTE_TYPES:
        case DomainActionTypes.UPDATE_CLAIM_REASONS:
        case DomainActionTypes.UPDATE_ROUTING_TASK_SEARCH:
        case DomainActionTypes.UPDATE_ELS_SEARCH:
        case DomainActionTypes.UPDATE_THIRD_PARTY_SHIPMENTS_SEARCHES:
        case DomainActionTypes.UPDATE_MASS_UNITS:
        case DomainActionTypes.UPDATE_DISTANCE_UNITS:
        case DomainActionTypes.UPDATE_STATE_CODES_DOMAIN:
        case DomainActionTypes.UPDATE_STATES_ABBR_DOMAIN:
        case DomainActionTypes.UPDATE_SERVICE_LEVELS_DOMAIN_TOKEN:
        case DomainActionTypes.UPDATE_PRICING_RULE:
        case DomainActionTypes.UPDATE_OPS_HUB_DOMAIN:
        case DomainActionTypes.UPDATE_PODS_FAILURE_REASONS:
        case DomainActionTypes.UPDATE_EVENT_IMAGE_TYPES:
        case DomainActionTypes.UPDATE_HUB_METRO_CONFIGURATIONS:
        case DomainActionTypes.UPDATE_PARCEL_CLAIM_SEARCH:
            if (!action.payload.domain) return state;
            return {
                ...state,
                list: {
                    ...(state.list && state.list),
                    [action.payload.domain.name]: action.payload.domain.value,
                },
            };

        default:
            return state;
    }
};

export default reducer;
