import { BTAction } from 'redux/constants';
import { AppActionTypes, AppError  , AppPayload} from './constants';

export const setLoading = (isLoading: boolean) => {
    const action = {
        type: AppActionTypes.SET_LOADING,
        payload: {
            loading: isLoading,
        },
    };

    return action;
};

export const setTableHasUpdated = () => ({
    type: AppActionTypes.SET_TABLE_HAS_UPDATES,
    payload: {},
})

export const setAppError = (error: AppError | null) => {
    const action: BTAction<AppPayload> = {
      type: AppActionTypes.SET_ERROR,
      payload: {
        error
      }
    }
  
    return action;
  }
