import "material-icons/iconfont/material-icons.css";
import config from "../../../../config";
import { defaultMapOptionsValues } from "../../../../redux/fleetView/constants";
import { faCar, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { ApexOptions } from "apexcharts";
import LocalStorage from "../../../../helpers/api/LocalStorage";
import failed from "../../../../assets/images/redWarning.svg";
import succeeded from "../../../../assets/images/greenRoundedCheck.svg";
import pending from "../../../../assets/images/grayPendingTasks.svg";

export const containerStyle = {
  width: '100%',
  minHeight: '410px',
  height: 'calc(100vh - 215px)',
  borderRadius: '5px',
};

export interface TaskModel {
  status: string | null;
  type: string | null;
  outcome: string | null;
  estimatedCompletionTime: Date | null;
  journeySegments: google.maps.journeySharing.VehicleWaypoint[] | null;
}

export interface AnchorPoint {
  width: number,
  height: number,
}

export interface VehicleInfoWindowAttributes {
  Driver?: string,
  Email?: string,
  FirstName?: string,
  LastName?: string,
  Status?: string,
  speed?: number | string ,
  time?: string | null,
  navigationStatus?: string | null,
  vehicleId?: string,
  timeSinceLastLocationUpdate?: string | number | null,
  locationStale?: string | null,
  driverStatistics?: {[key: string]: number|null} | null,
  lastUpdate?: any,
}

export interface MarkerFullInfo {
  marker: google.maps.Marker,
  attributes: VehicleInfoWindowAttributes;
}

export type AllMarkersType = Record<string, google.maps.Marker>;
export type VehicleMarkersType = Record<string, MarkerFullInfo>;

export interface MarkerIconModel {
  displayName: string;
  icon: google.maps.Icon | google.maps.Symbol | string | null;
  anchor: AnchorPoint | undefined
}
export interface MarkerTaskIconModel {
  displayName: string;
  icon?: google.maps.Icon | google.maps.Symbol | string | null;
  anchor: AnchorPoint | undefined
}

export interface MapOptionsModel {
  showAnticipatedRoutePolyline: boolean;
  showTakenRoutePolyline: boolean;
  destinationMarker: MarkerIconModel;
  vehicleMarker: MarkerIconModel;
  vehicleDetailsMarker: MarkerIconModel;
  outcomeMarker: MarkerIconModel;
  pingMarker: MarkerIconModel;
  noClear: boolean;
  mapId: string,
}

export const COLOR_ERROR = '#C9171F';
export const COLOR_ERROR_BACKGROUND = 'rgb(201, 23, 31, 0.1)';
export const COLOR_SUCCESS = '#00865F';
export const COLOR_SUCCESS_BACKGROUND = 'rgb(0, 134, 95, 0.1)';
export const COLOR_PENDING = '#737683';
export const COLOR_PENDING_BACKGROUND = 'rgb(115, 118, 131, 0.1)';

export const COLOR_PALETTE = {
  ERROR: {
    normal: COLOR_ERROR,
    background: COLOR_ERROR_BACKGROUND
  },
  SUCCESS: {
    normal: COLOR_SUCCESS,
    background: COLOR_SUCCESS_BACKGROUND
  },
  PENDING: {
    normal: COLOR_PENDING,
    background: COLOR_PENDING_BACKGROUND
  },
}

export const returnTypeIcon = (iconType: TaskOutcomeStatus) => {
  switch (iconType.toUpperCase()) {
    case TaskOutcomeStatus.FAILED.toUpperCase(): return failed;
    case TaskOutcomeStatus.SUCCEEDED.toUpperCase(): return succeeded;
    case TaskOutcomeStatus.NULL.toUpperCase(): return pending;
    default: return pending;
  }
}

export interface ReturnTypeColorInt {
  background: string;
  normal: string;
}

export const returnTypeColor = (iconColor: TaskOutcomeStatus): ReturnTypeColorInt => {
  switch (iconColor.toUpperCase()) {
    case TaskOutcomeStatus.FAILED.toUpperCase(): return COLOR_PALETTE.ERROR;
    case TaskOutcomeStatus.SUCCEEDED.toUpperCase(): return COLOR_PALETTE.SUCCESS;
    case TaskOutcomeStatus.NULL.toUpperCase(): return COLOR_PALETTE.PENDING;
    default: return COLOR_PALETTE.PENDING;
  }
}

export enum TaskOutcomeStatus {
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  NULL = 'NULL',
  OUTCOME = 'OUTCOME',
}

export namespace TaskOutcomeStatusUtils {
  export function getValue(key: keyof typeof TaskOutcomeStatus): TaskOutcomeStatus {
    return (TaskOutcomeStatus as any)[key] ?? null;
  }

  export function getKey(value: TaskOutcomeStatus): keyof typeof TaskOutcomeStatus | null {
    return (Object.keys(TaskOutcomeStatus) as Array<keyof typeof TaskOutcomeStatus>).find(key => TaskOutcomeStatus[key] === value) ?? null;
  }
}

export enum taskFinalStatus {
  NOT_STARTED = "Not Started",
  EN_ROUTE = "En Route",
  DELIVERED = "Delivered",
  FAILED = "Failed",
  NULL = "Not Completed",
  PENDING = "Pending",
}
export enum vehicleMarkerType {
  DRIVER_PAGE = 1,
  TASK_PAGE = 0,
}

export enum statisticsType {
  FULL = 1,
  POCKET = 0,
}

export enum dateTimeForQuery {
  YESTERDAY = 'yesterday',
  TODAY = 'today'
}

export const ICON_VEHICLE = `${window.location.origin}/fleet-ops/vehicleSmall_.png`;
export const ICON_DETAILS_VEHICLE = `${window.location.origin}/fleet-ops/vehicleDetails.png`;
export const ICON_SUCCEEDED = `${window.location.origin}/fleet-ops/succeededSmall.png`;
export const ICON_FAILED = `${window.location.origin}/fleet-ops/failureSmall.png`;
export const ICON_PENDING = `${window.location.origin}/fleet-ops/pendingSmall.png`;
export const ICON_PENDING_GRAY = `${window.location.origin}/fleet-ops/pendingSmallGray.png`;
export const ICON_TRAFFIC = `${window.location.origin}/fleet-ops/traffic.png`;
export const ICON_OUTCOME = `${window.location.origin}/fleet-ops/outcomeSmall.png`;
export const ICON_OUTCOME_PARCEL = `${window.location.origin}/fleet-ops/parcel.png`;
export const ICON_CLUSTER_MARKER = `${window.location.origin}/fleet-ops/cluster.png`;
export const ICON_COOL_CAR = `${window.location.origin}/fleet-ops/coolCar.png`;
export const ICON_VEHICLE_MARKER = `${window.location.origin}/fleet-ops/vehicleSmall_.png`;
export const ICON_VEHICLE_MARKER_TASKS = `${window.location.origin}/fleet-ops/vehicleOrangeSmall.png`;

export const MAX_ZOOM_VALUE_ON_MAP = 21;
export const deliveryTaskStatusGetter = (taskSCurrentStatus: string, taskStatus = TaskOutcomeStatus.SUCCEEDED) =>
  taskSCurrentStatus === taskStatus?.toUpperCase();

export enum markerColors {
  FILLED_DEFAULT = 'rgba(0,0,0,0.12)',
  FILLED_SUCCESS = 'rgb(0,73,183)',
  FILLED_FAILED = 'rgb(232,15,0)',
  HIGHLIGHTED_FILLED_SUCCESS = 'rgba(255,234,0,0.99)',
  DEFAULT_STROKE_COLOR = '#000000',
  HIGHLIGHTED_STROKE_COLOR = 'rgba(0,0,0,0.45)',
}

export enum markerIcons {
  DEFAULT = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  SUCCESS = "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
  FAILED = "M12,2C8.14,2 5,5.14 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.14 15.86,2 12,2M9.59,5.17L12,7.58L14.41,5.17L15.83,6.58L13.41,9L15.83,11.41L14.41,12.83L12,10.41L9.59,12.83L8.17,11.41L10.59,9L8.17,6.58",
}
export const markerImageIcons = {
  DEFAULT: ICON_PENDING_GRAY,
  SUCCESS: ICON_SUCCEEDED,
  OUTCOME: ICON_OUTCOME,
  FAILED: ICON_FAILED,
}

export enum markerLabels {
  DELIVERED = 'Delivered',
  FAILED = 'Failed',
  UNKNOWN = 'Not Completed',
}

export enum DriverStatusLabels {
  UNKNOWN_NAVIGATION = 'Unknown navigation',
  NO_GUIDANCE = 'No guidance',
  ENROUTE_TO_DESTINATION = 'Enroute to destination',
  OFF_ROUTE = 'Off route',
  ARRIVED_AT_DESTINATION = 'Arrive at destination',
  FINISHED_ROUTES = 'Finished routes',
}

export namespace DriverStatusLabels {
  export function getLabel(status: any): any {
    return DriverStatusLabels[status as keyof typeof DriverStatusLabels];
  }
}

export enum PackageType {
  PLUS_18 = '18+',
  PLUS_21 = '21+',
  SIGNATURE_REQUIRED = 'Signature required',
  PERISHABLE = 'Perishable',
}

export namespace PackageTypeUtils {
  export function getLabel(status: any): any {
    return PackageType[status as keyof typeof PackageType];
  }

  export function getValue(key: keyof typeof PackageType): PackageType {
    return (PackageType as any)[key] ?? null;
  }

  export function getKey(value: PackageType): keyof typeof PackageType | null {
    return (Object.keys(PackageType) as Array<keyof typeof PackageType>).find(key => PackageType[key] === value) ?? null;
  }
}


export const authTokenFetcher = async () => {
  const response = await fetch(
    config.GFE_TOKEN_URL, {mode:'cors'},
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const responseJson = await response.json();

  return {
    token: responseJson.token,
    expiresInSeconds: responseJson.expiration_timestamp_ms,
  };
};

export interface SvgMarkerOptions {
  icon: google.maps.Symbol | google.maps.Icon ;
  path: string | undefined;
  fillColor?: string | null;
  strokeColor?: string | null;
  strokeWeight?: number;
  scaledSize?: any;
}

export const iconFunction = ({
                        icon,
                        path = markerIcons.DEFAULT,
                        fillColor = markerColors.FILLED_SUCCESS,
                        strokeColor = markerColors.DEFAULT_STROKE_COLOR,
                        strokeWeight = 1
                      }: SvgMarkerOptions): null | google.maps.Symbol => {
  return {
    ...icon,
    scale: 1.7,
    path: icon && "path" in icon && icon.path !== markerIcons.DEFAULT ? icon.path : path,
    fillColor,
    strokeColor,
    strokeWeight,
  }
};

export const contentString =
  '<div id="content">' +
  '<div id="siteNotice"/>' +
  "" +
  '<h6 id="firstHeading" className="firstHeading">Details</h6>' +
  '<div id="bodyContent" style={{ height: 200, width: 200, align: "center" }} >' +
  'Loading...' +
  "</div>" +
  "</div>";

export const isMarkerSelected = (markerTrackingId: string, tracking: string): boolean =>
  markerTrackingId === tracking;
export const NO_DATA_MESSAGE =  'No data for this marker';
export const defaultTasksDetails = {
  toAddress: {
    name: NO_DATA_MESSAGE,
  }
}
export interface FetchTasksDetailsInterface {
  taskTrackingId?: string
  parcelId?: string;
  receiverName?: string;
  address?: string
  email?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const mapOptions:  google.maps.MapOptions = {
  mapId: config.GOOGLE_MAPS_MAP_ID,
  fullscreenControl: true,
  mapTypeControl: true,
  streetViewControl: false,
  maxZoom: MAX_ZOOM_VALUE_ON_MAP,
  zoom: 4.5,
  center: {
    lat: 39.2245625,
    lng: -100.3927786,
  }
};

export const regularVehicleMarkerDefaultOptions = {
  visible: true,
  icon: ICON_VEHICLE_MARKER,
  label: ''
}
export const regularVehicleTaskMarkerDefaultOptions = {
  visible: true,
  icon: ICON_VEHICLE_MARKER_TASKS,
  label: ''
}
export const regularTaskMarkerDefaultOptions = {
  visible: true,
  icon: ICON_PENDING,
  label: ''
}

export const setMapViewOptions = (locationProviderObj: any, refObj: any, mapOptions: MapOptionsModel = defaultMapOptionsValues) => {
  // @ts-ignore
  const mapViewOptionsFleet: google.maps.journeySharing.JourneySharingMapViewOptions = {
    element: refObj,
    locationProvider: locationProviderObj,
    automaticViewportMode : google.maps.journeySharing.AutomaticViewportMode.NONE,
    anticipatedRoutePolylineSetup: ({defaultPolylineOptions}) => {
      return {
        polylineOptions: defaultPolylineOptions,

        visible: mapOptions?.showAnticipatedRoutePolyline,
      };
    },
    takenRoutePolylineSetup: ({defaultPolylineOptions}) => {
      return {
        polylineOptions: defaultPolylineOptions,
        visible: mapOptions.showTakenRoutePolyline,
      };
    },
    waypointMarkerSetup: ({defaultMarkerOptions}) => {
      defaultMarkerOptions.visible = false;

      return {markerOptions: defaultMarkerOptions};
    },
    taskOutcomeMarkerSetup: ({defaultMarkerOptions}) => {

      return {markerOptions: defaultMarkerOptions};
    },
      /*
      taskOutcomeMarkerSetup: (props) => {

        const {defaultMarkerOptions} = props;

        defaultMarkerOptions.visible = false;
        defaultMarkerOptions.icon = undefined;
        defaultMarkerOptions.map = undefined;

        const content = buildContent({});

        return {
          markerOptions: {
            ...defaultMarkerOptions,
            position: defaultMarkerOptions.position,
            title: defaultMarkerOptions.title,
            zIndex: null, //defaultMarkerOptions.zIndex,
            content: content,
          }
        };
      },
    unsuccessfulTaskMarkerSetup: (props) => {
      const {defaultMarkerOptions} = props;

      defaultMarkerOptions.visible = false;
      defaultMarkerOptions.icon = undefined;
      defaultMarkerOptions.map = undefined;

      const content = buildContent({type: 'receipt-text-clock-outline'});

      return {
        markerOptions: {
          ...defaultMarkerOptions,
          position: defaultMarkerOptions.position,
          title: defaultMarkerOptions.title,
          zIndex: null, //defaultMarkerOptions.zIndex,
          content: content,
        }
      };
    },
    successfulTaskMarkerSetup: (props) => {
      const {defaultMarkerOptions} = props;

      defaultMarkerOptions.visible = false;
      defaultMarkerOptions.icon = undefined;
      defaultMarkerOptions.map = undefined;

      const content = buildContent({type: 'check-circle-outline'});

      return {
        markerOptions: {
          ...defaultMarkerOptions,
          position: defaultMarkerOptions.position,
          title: defaultMarkerOptions.title,
          zIndex: null, //defaultMarkerOptions.zIndex,
          content: content,
        }
      };

    },*/
    vehicleMarkerSetup:  (props) => {
      const {defaultMarkerOptions} = props;

      defaultMarkerOptions.visible = false;
      // defaultMarkerOptions.icon = ICON_VEHICLE_MARKER;
      return {markerOptions: defaultMarkerOptions};
    },

    mapOptions: {
      mapId: config.GOOGLE_MAPS_MAP_ID,
      fullscreenControl: true,
      mapTypeControl: true,
      maxZoom: MAX_ZOOM_VALUE_ON_MAP,
    }
  };

  return mapViewOptionsFleet;
};

export const mapLibraries = ['journeySharing', 'marker', 'routes'];

export const objectsAreEqual = (o1 : any, o2 : any): boolean =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => objectsAreEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1: any, a2: any) =>
  a1.length === a2.length && a1.every((o: any, idx: any) => objectsAreEqual(o, a2[idx]));

export const getIndexNumberArray = (rawArray: any[]) => {
  return rawArray.map((task: any) =>({
    trackingId: task.trackingId,
    index: task.index,
  }))
}

export const PNG_ICON_DIMENSIONS = {
  width: 25,
  height: 34,
}

export const iconDimensionsFn = (markerIcon: MarkerIconModel) => ({
  width: markerIcon.anchor?.width ?? PNG_ICON_DIMENSIONS.width,
  height: markerIcon.anchor?.height ?? PNG_ICON_DIMENSIONS.height,
});

export const fontIconDimensions = (iconObj: IconDefinition) => ({
  width: iconObj.icon[0],
  height: iconObj.icon[1]
});

const iconPoint = (iconObj: IconDefinition = faLocationPin) => {
  const iconDimension = fontIconDimensions(iconObj);

  return ({
    equals(other: google.maps.Point | null): boolean {
      return false;
    },
    toString(): string {
      return iconObj.icon[3];
    },
    x: iconDimension.width,
    y: iconDimension.height,
  })
}

export const font_icon = (iconObj: IconDefinition, fillColor: string = "rgb(0,0,0)", strokeColor: string = "rgba(0,0,0,0.25)") => ({
  path: iconObj.icon[4] as string,
  fillColor: fillColor,
  fillOpacity: 1,
  anchor: iconPoint(iconObj),
  rotation: 1,
  strokeWeight: 1,
  strokeColor: strokeColor,
  scale: 0.03,
});

export const MARKERS_ICON = {
  USE_DEFAULT: { displayName: 'Default', icon: null, anchor: undefined},
  USE_PNG_VEHICLE: {
    displayName: 'pngVehicle',
    icon: ICON_VEHICLE_MARKER,
    anchor: PNG_ICON_DIMENSIONS,
  },
  USE_PNG_DETAILS_VEHICLE: {
    displayName: 'pngDetailsVehicle',
    icon: ICON_DETAILS_VEHICLE,
    anchor: PNG_ICON_DIMENSIONS,
  },
  USE_PNG_OUTCOME: {
    displayName: 'pngOutcome',
    icon: ICON_OUTCOME,
    anchor: PNG_ICON_DIMENSIONS,
  },
  USE_LABEL_ICON_VEHICLE: {
    displayName: 'fontFaCar',
    icon: font_icon(faCar),
    anchor: fontIconDimensions(faCar)
  },
};

export const IMMEDIATELY_POLLING_INTERVAL_MS = 10;
export const DEFAULT_POLLING_INTERVAL_MS = 5000;
export const MEDIUM_POLLING_INTERVAL_MS = 10000;
export const LONG_POLLING_INTERVAL_MS = 30000;
export const SUPER_LONG_POLLING_INTERVAL_MS = 86400000; // 24 hrs

export enum METRO_CODES {
  ATL = '33.78224165648329,-84.35715985983968,10',
  CHI = '41.97171803174912,-87.85006692734375,10',
  CIN = '39.107913643775895,-84.50583979042969,11',
  CLE = '41.497532,-81.70586,11',
  COL = '39.9829515,-82.990829,11',
  DET = '42.481766,-83.3677175,10.25',
  DFW = '32.7992137,-96.9060453,9.429',
  AUS = '30.3255956,-97.8187263,11',
  SAT = '29.43233040054896,-98.50304679912108,11',
  HOUSTON = '29.81403441693306,-95.33125360820313,10',
  IND = '39.7797845,-86.13275,10.57',
  LAX = '34.020479,-118.4117325,10',
  LEX = '38.0283299,-84.471565,11',
  LOU = '38.2391515,-85.6951356,11.25',
  MAD = '43.0849935,-89.4065921,12',
  MAN = '40.7590403,-74.0392709,12',
  MIL = '40.7371146,-74.3496245,14',
  MKE = '43.0578914,-87.9674963,11',
  MSP = '44.970697,-93.2614785,9.85',
  NSH = '36.1866405,-86.7852455,10',
  NYC = '40.999906264852854,-73.35851272533291,9',
  SBE = '41.677331244126464,-86.23949228808591,12',
  STL = '38.7025331,-90.5165241,10.29',
  TOL = '41.656555,-83.5742561,11.57',
  GRA = '42.9082376,-85.6998842,10',
}

export const anulateMarker = (marker: any) => {
  marker?.setMap(null);
  marker?.setVisible(false);

  return marker;
}

export const svgMarker = (element: any, markerIcon: MarkerIconModel, labelText: string = '', className = `vehicle_marker_label`) => {

  element.marker.icon = {
    path: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(markerIcon.icon && markerIcon.icon.toString() || ''),
    scale: 0.4,
    labelOrigin: new google.maps.Point(0, 0),
    // anchor: new google.maps.Point(iconDimensions.width, iconDimensions.height),
    rotation: null,
  };
  element.marker.optimized = false;

  // custom label on marker
  element.marker.setLabel(labelText.length > 0 ? {
    text: labelText,
    className: className
  } : null);

  return element;
}

export const customMarker = (element: any, markerIcon: MarkerIconModel, labelText: string = '', className = `vehicle_marker_label`) => {
  const iconDimensions = iconDimensionsFn(markerIcon);

 // custom icon on marker
  element.marker.icon = {
    url: (markerIcon.icon && markerIcon.icon.toString()) ?? '',
    size: new google.maps.Size( iconDimensions.width, iconDimensions.height),
    origin: new google.maps.Point(0, 0),
  }

  element.marker.optimized = false;

  // custom label on marker
  element.marker.setLabel(labelText.length > 0 ? {
    text: labelText,
    className: className
  } : null);

  return element;
};

export const addNewControl = (index: number | string, style: string) => {
  const div = document.createElement('div');
  // @ts-ignore
  div.id = index;
  // div.index = index;
  div.style.cssText = style;

  return div;
}

export const millisToTime = (msValue: number) => {
  const milliseconds = msValue % 1000
  const seconds = Math.floor((msValue / 1000) % 60)
  const minutes = Math.floor((msValue / (60 * 1000)) % 60)
  const hours = Math.floor((msValue / (3600 * 1000)) % 3600)
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`
}

export const apexBarChartOptsDriversTasks: ApexOptions = {
  noData: {
    text: undefined,
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined
    }
  },
  grid: {
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    height: 80,
    type: 'radialBar',
  },
  stroke: {
    lineCap: "round",
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        margin: 2,
        startAngle: -90,
        endAngle: 90,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15
        }
      },
      hollow: {
        margin: 0,
        size: "48%",
        background: "rgba(191,255,0,0.11)"
      },
      dataLabels: {
        name: {
          fontSize: "12px",
          offsetY: 30,
          color: '#2E93fA',
        },
        value: {
          fontSize: "14px",
          fontWeight: "bold",
          offsetY: -12,
          color: '#66DA26',

        },
      },
    },
  },
};

export const setApexBarChartOptsDriversTasks = (valueToShow: number, colors: string[], labels: string[]): ApexOptions => ({
  noData: {
    text: undefined,
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined
    }
  },
  grid: {
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    height: 80,
    type: 'radialBar',
  },
  stroke: {
    lineCap: "round",
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        margin: 2,
        startAngle: -90,
        endAngle: 90,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15
        }
      },
      hollow: {
        margin: 0,
        size: "48%",
        background: "rgba(191,255,0,0.11)"
      },
      dataLabels: {
        name: {
          fontSize: "10px",
          offsetY: 38,
          color: '#2E93fA',
        },
        value: {
          fontSize: "11px",
          fontWeight: "bold",
          offsetY: -12,
          color: '#66DA26',
          formatter: function (val ) {
            return `${LocalStorage.getVehicleTotalTasks()}`;
          }
        },
      },
    },
  },
  colors: colors,
  labels: labels,
})

export const stackedBarProps: ApexOptions = {
  chart: {
    width: 120,
    type: 'pie',
  },
  legend: {
    fontSize: '8px',
    horizontalAlign: 'left',
    offsetY: -8,
  },
  labels: ['Succeeded', 'Failed', 'Uncompleted'],
  colors: ['#2E93fA', 'rgba(255,0,30,0.65)', 'rgba(0,0,0,0.4)'],
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '10px',
      colors: ['rgba(0,0,0,0.8)']
    },
    background: {
      enabled: true,
      foreColor: 'rgba(255,221,0,0.61)',
      borderWidth: 0,
      dropShadow: {},
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 140
      },
      legend: {
        position: 'bottom'
      }
    }
  }]
};

export const apexBarChartStackedData = [55, 1];

export const defaultColumnOnTableProps = {
  defaultCanSort: true,
  sortDescFirst: true,
}

export const defaultSortingColumnOnTableProps = {
  ...defaultColumnOnTableProps,
  sortType: (prev: any, curr: any, columnId: any) => {
    return sortItems(prev, curr, columnId);
  },
}

export function globalSearch(array: any[], textArray: any[] | null, filterColumns: any[]) {
  if(textArray && !textArray[0]) return array;

  textArray = textArray && textArray.map((toFind: any) => toFind?.toLowerCase());

  return array.filter((a) => {
    return textArray && textArray.every((t: any) => {
      return filterColumns.some((f: any) => {
        return a[f]?.toString()?.toLowerCase()?.indexOf(t) !== -1;
      });
    });
  });
}

export function cleanStringify(object: any) {
  if (object && typeof object === 'object') {
    object = copyWithoutCircularReferences([object], object);
  }
  return JSON.stringify(object);

  function copyWithoutCircularReferences(references: any[], object: { [x: string]: any; }) {
    let cleanObject = {};
    Object.keys(object).forEach(function(key) {
      const value = object[key];
      if (value && typeof value === 'object') {
        if (references.indexOf(value) < 0) {
          references.push(value);
          // @ts-ignore
          cleanObject[key] = copyWithoutCircularReferences(references, value);
          references.pop();
        } else {
          // @ts-ignore
          cleanObject[key] = '###_Circular_###';
        }
      } else if (typeof value !== 'function') {
        // @ts-ignore
        cleanObject[key] = value;
      }
    });
    return cleanObject;
  }
}

export const focusEventNames = ["visibilitychange", "webkitvisibilitychange", "blur"];
export enum eventFocusStatus {
  DISABLED = 'true',
  ENABLED = 'false',
}

export const sortItems = (prev: any, curr: any, columnId: string) => {
  // Get the values from the original objects
  const prevValue = prev?.original[columnId];
  const currValue = curr?.original[columnId];

  // Check if both values are strings
  if (typeof prevValue === 'string' && typeof currValue === 'string') {
    // Try to convert the strings to numbers
    const prevNum = Number(prevValue);
    const currNum = Number(currValue);

    // Check if both values are numeric strings
    if (!isNaN(prevNum) && !isNaN(currNum)) {
      // Both values are numeric strings, so sort them numerically
      return prevNum - currNum;
    } else {
      // At least one of the values is not a numeric string, so sort them alphabetically
      return prevValue.localeCompare(currValue);
    }
  } else {
    // Warn if either value is not a string
    console.warn('Both prev and curr values should be strings');
    return 0;
  }
};

export const setVehicleMarkerLabel = (element: any, type: number = vehicleMarkerType.DRIVER_PAGE) => {
  const getDriverNameInitials = `${element?.vehicle?.driver?.FirstName.toString().charAt(0).toUpperCase() || '-'}${element?.vehicle?.driver?.LastName.toString().charAt(0).toUpperCase() || '-'}`
  const getDriverFullName = `${element?.vehicle?.driver?.FirstName.toString().toLowerCase() || '-'} ${element?.vehicle?.driver?.LastName.toString().toLowerCase() || '-'}`

  // custom label on marker
  element.marker.setLabel( {
    text: getDriverNameInitials,
    // className: type === vehicleMarkerType.DRIVER_PAGE ? `vehicle_label_style` : `vehicle_label_style_for_tasks`,
    className: `vehicle_label_style`,
  });

  element.marker.setTitle(`vehicle for ${getDriverFullName}`)

  return element;
};

interface buildContent {
  label?: string;
  type?: string;
  ariaLabel?: string;
  iconLabel?: string;
  markerColor?: string;
}

export enum VehicleMarkerBackgroundColor {
  DELIVERED = 'check-circle-outline',
  FAILED = 'close-circle-outline',
  PENDING = 'receipt-text-clock-outline',
  UNDEFINED = 'receipt-text-clock-outline',
}

export namespace VehicleMarkerBackgroundColor {
  export function getClassColor(mode: VehicleMarkerBackgroundColor = VehicleMarkerBackgroundColor.UNDEFINED): string {
    switch (mode) {
      case VehicleMarkerBackgroundColor.DELIVERED:
        return '#0288D1';
      case VehicleMarkerBackgroundColor.FAILED:
        return '#FF5A5AFF';
      case VehicleMarkerBackgroundColor.PENDING:
        return '#FF9800';
      default:
        return '#A4A4A4FF';
    }
  }

  export function getMdiIcon(mode: VehicleMarkerBackgroundColor): string {
    switch (mode) {
      case VehicleMarkerBackgroundColor.DELIVERED:
        return 'check-circle-outline';
      case VehicleMarkerBackgroundColor.FAILED:
        return 'close-circle-outline';
      case VehicleMarkerBackgroundColor.PENDING:
        return 'receipt-text-clock-outline';
      default:
        return 'receipt-text-clock-outline';
    }
  }
}
export const buildContent = ({ type, label, ariaLabel, iconLabel, markerColor } : buildContent) => {
  const content = document.createElement("div");

  ariaLabel = !ariaLabel ? 'defaultAriaMarker' : ariaLabel;
  content.setAttribute("aria-label", ariaLabel);
  content.classList.add("htmlAdvancedMarkerStyle");
  content.style.cssText = `--color:${markerColor}`

  content.innerHTML = `
    <div class="icon"}>
      <i aria-hidden="true"
        class="mdi mdi-${(type) ?? 'dots-horizontal-circle-outline'}"
        title="${iconLabel ?? ''}"
      ></i>
      <p class="marker_label_style" ariaLabel={ariaLabel'_label'}>${label ?? '...'}</p>
    </div>
  `;
  return content;
}

export const capitalizeString = (text: string): string => {
  if (text.length <= 0) return ''

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
export enum DriverStatusBadge {
  ACTIVE = 1,
  DELAYED = 2,
  FINISHED = 3
}

export namespace DriverStatusBadge {
  export function getName(status: DriverStatusBadge): string {
    return capitalizeString(DriverStatusBadge[status]);
  }

  export function getColor(status: DriverStatusBadge): string {
    switch (status) {
      case DriverStatusBadge.ACTIVE:
        return '#00865F';
      case DriverStatusBadge.DELAYED:
        return '#FF9900';
      case DriverStatusBadge.FINISHED:
        return '#737683';
      default:
        return '#737683';
    }
  }
}

export const filterAttributes = (object: {[key: string]: any}, excludedAttributes: string): {[key: string]: any} => {
  const attributes = excludedAttributes.split(',');
  return Object.keys(object)
    .filter(key => !attributes.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {} as {[key: string]: any});
}

